<template>
  <div>
    <h1>Welcome {{currentUser.firstName }} {{currentUser.lastName}}</h1>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data(){
    return {

    }
  },
  computed:{
    ...mapGetters("auth", ["isLoggedIn", "currentUser"]),
  }

};
</script>

<style>
</style>